import React from 'react';

export default function AuthorBox({ name, image, bio }) {
	return (
		<div className="c-resources-author-box">
			<div className="c-resources-author-box__img" style={{ backgroundImage: `url(${image})` }} />
			<div className="c-resources-author-box__body">
				<p className="c-resources-author-box__name">{name}</p>
				<p className="c-resources-author-box__bio">{bio}</p>
			</div>
		</div>
	);
}
